import './vendors/h5bp';

/* 
 * creación del mapa con als ubicaciones
 *
 * - Ejecucion en: $(document).ready()
 */
function goOffices() {

  // Motos
  var markers = [
      ['<h4>25 de Junio 644 Dto 3 </h4><p> Tel: (0343) 155 127095 <br>Mail: info@coinciv.com.ar <br> Paraná, Entre Rios, Argentina</p>', -31.727896, -60.538795]
  ];


  var latlng = new google.maps.LatLng(-31.727896, -60.538795);
  var mapOptions = {
    zoom: 15,
    center: latlng,
    mapTypeControl: false,
    scaleControl: false,
    panControl: false,
    rotateControl: false,
    zoomControl: false,
    streetViewControl: false,
    scrollwheel: false,
    styles: [ 
      { "featureType": "landscape", "stylers": [ { "color": "#ffffff" } ] },
      { "featureType": "road", "elementType": "geometry", "stylers": [ { "color": "#a2d3bf" } ] },
      { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#00593d" } ] },
      { "featureType": "water", "stylers": [ { "color": "#00593d" } ] } 
    ]
  };
  var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
  var infowindow = new google.maps.InfoWindow();
  var marker;
  var i;
  for (i = 0; i < markers.length; i++) {
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(markers[i][1], markers[i][2]),
      map: map,
      icon: './assets/media/location-pin.png'
    });
    // google.maps.event.addListener(marker, 'click', (function (marker, i) {
      // return function () {
        infowindow.setContent(markers[i][0]);
        infowindow.open(map, marker);
      // };
    // })(marker, i));
  }
}

/* 
 * Envío del formulario de contacto
 *
 * - Ejecucion en: $(document).ready()
 */

function contactFormGo(){

  $("#contact-submit").on('click', function(e){//formulario de contacto
    e.preventDefault();

    var okflag=true;
    $('input,textarea').filter('[required]:visible').each(function(i, requiredField){
      if($(requiredField).val()==''){
        if(okflag==true)$('#msg-all').fadeIn().delay(5000).fadeOut();
        okflag=false; 
        $(requiredField).addClass("contact-error");
      }
      else $(requiredField).removeClass("contact-error");
    });

    if(okflag==true)
      $('#msg').addClass("backGload").fadeIn().load('contacto.php', {
        nombre: $('#contact-name').val(),
        telefono: $('#contact-phone').val(),
        email: $('#contact-email').val(),        
        mensaje: $('#contact-message').val()
      }, function(){
        $('input, textarea').val("");
        $('#msg').removeClass("backGload");
      });

  });

}


/* 
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
// function goMaterialize() {
//   $('.slider').slider({height:500});
//   $('.slider2').slider({height:400});
//   $(".button-collapse").sideNav();
// }


/* 
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
//  function goSliders() {

//   $(".img-slider").slick({
//     arrows: true,
//     dots: true,
//     autoplay: true
//   });

//   $(".latest-news").slick({
//     arrows: false,
//     dots: true,
//     autoplay: true
//   });

// }

/*
 * 
 * Ejecuto el llamado de todas las funciones Nac y Pop
 *
 */
$(document).ready(function(){

  // goSliders();
  // goMaterialize();

    contactFormGo();
    goOffices();


});